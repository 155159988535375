





























































































































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/sunfangbao';
import * as aes from '@/utils/aes/public';
export default Vue.extend({
  name: 'certificationAuditList',
  data() {
    return {
      rules: {
        reply: [
          {
            required: true,
            message: '处理内容不能为空',
            validateTrigger: 'blur',
          },
        ],
      },
      type: '',
      loading: false,
      tableList: [],
      tableLoading: false,
      certificationType: 'enterprise',
      ModalForm: {
        phone: '',
        email: '',
        appellation: '',
        areaCode: '',
        areaName: '',
        message: '',
        reply: '',
        status: '',
        createDate: '',
      },
      searchForm: {
        phone: '',
        areaCode: [] as [],
        status: '0',
        createDate: [],
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        {
          title: '序号',
          align: 'left',
          dataIndex: 'number',
          width: '80px',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '手机号码',
          dataIndex: 'phone',
        },
        {
          title: '称呼',
          dataIndex: 'appellation',
        },
        {
          title: '工程地点',
          dataIndex: 'areaName',
        },
        {
          title: '咨询内容',
          dataIndex: 'message',
          width: '20%',
        },
        {
          title: '咨询时间',
          dataIndex: 'createDate',
          scopedSlots: { customRender: 'createDate' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'options',
          scopedSlots: { customRender: 'options' },
        },
      ],
      certificationAuditData: [],
      showModal: false,
      addrList: [],
    };
  },
  mounted() {
    this.getList();
    this.getAddr();
  },
  methods: {
    moment,
    decryptData(res) {
      return JSON.parse(aes.decrypt(res.result));
    },
    isDisabled() {
      return ['see'].includes(this.type);
    },
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    getAddr() {
      api.getRegionTier().then((RES) => {
        const res = this.decryptData(RES);
        if (RES.status === 200) {
          this.addrList = res;
        }
      });
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getList();
    },
    onSubmit() {
      (this.$refs.formFormRef as HTMLFormElement).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        api
          .consultUpdate(this.ModalForm)
          .then((RES) => {
            const res = this.decryptData(RES);
            if (RES.status == 200) {
              this.$message.success('提交成功！');
              this.showModal = false;
              this.handleSearchReset();
            } else {
              this.$message.error(res);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.searchForm = {
        phone: '',
        areaCode: [],
        status: '0',
        createDate: [],
      };
      this.pagination.current = 1;
      this.getList();
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    getList() {
      this.tableLoading = true;
      let postData = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        startDate: '',
        endDate: '',
        ...this.searchForm,
        areaCode: this.searchForm.areaCode.length
          ? this.searchForm.areaCode[this.searchForm.areaCode.length - 1]
          : '',
      };
      if (postData.createDate.length) {
        postData.startDate = postData.createDate[0] + ' 00:00:00';
        postData.endDate = postData.createDate[1] + ' 23:59:59';
      }

      api
        .consultList(postData)
        .then((RES) => {
          const res = this.decryptData(RES);

          console.log('列表数据', res);
          if (RES.status === 200) {
            this.pagination.total = res.total;
            this.tableList = res.records;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async open(type, id) {
      const RES = await api.getDetails(id);
      const res = this.decryptData(RES);
      this.ModalForm = res;
      this.showModal = true;
      this.type = type;
    },
  },
});
