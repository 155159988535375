/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2021-12-06 14:18:10
 * @LastEditors: sunchen
 * @LastEditTime: 2023-05-16 16:58:17
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

// 查询详情
export function getDetails(id: string): Promise<BaseResponse> {
  return request({
    url: `/1/consult/${id}`,
    method: 'GET',
    baseURL: '/sfbApi',
  });
}

// 咨询回复
export function consultUpdate(data): Promise<BaseResponse> {
  return request({
    url: `/1/consult/update`,
    method: 'POST',
    baseURL: '/sfbApi',
    data,
  });
}

// 客服人员获取所有咨询列表
export function consultList(data: any): Promise<BaseResponse> {
  return request({
    url: `/1/consult/list`,
    method: 'POST',
    baseURL: '/sfbApi',
    data,
  });
}

// 获取河北地区 名称code 市在河北省下级
export function getRegionTier(): Promise<BaseResponse> {
  return request({
    url: `/1/region/tier`,
    method: 'GET',
    baseURL: '/sfbApi',
  });
}
